import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { HYDRATE } from 'next-redux-wrapper';

const indexSlice = createSlice({
  name: 'app',
  initialState: {
    pageId: null,
    isRobot: false,
    navVisible: true,
    navIsOpen: false,
    languages: [],
    footerProgress: 0,
  },
  reducers: {
    setPageId: (state, action) => {
      state.pageId = action.payload;
    },
    setRobot: (state, action) => {
      state.isRobot = action.payload;
    },
    setNavVisible: (state, action) => {
      state.navVisible = action.payload;
    },
    setNavIsOpen: (state, action) => {
      state.navIsOpen = action.payload;
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setFooterProgress: (state, action) => {
      state.footerProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      return (state = {
        ...state,
        ...action.payload.app,
      });
    });
  },
});

export const store = () =>
  configureStore({
    reducer: {
      app: indexSlice.reducer,
    },
  });

export const { setPageId, setRobot, setNavVisible, setNavIsOpen, setLanguages, setFooterProgress } = indexSlice.actions;
export const wrapper = createWrapper(store, { debug: false });

export const selectPageId = (state) => state.app.pageId;
export const selectIsRobot = (state) => state.app.isRobot;
export const selectNavVisible = (state) => state.app.navVisible;
export const selectNavIsOpen = (state) => state.app.navIsOpen;
export const selectLanguages = (state) => state.app.languages;
export const selectFooterProgress = (state) => state.app.footerProgress;
