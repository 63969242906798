import '../styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';
import { GoogleReCaptchaProvider } from '../components/recaptcha/GoogleReCaptchaProvider';
import AzureAppInsights from '../components/general/AzureAppInsights';
import { wrapper } from '../store/index';
import { Provider } from 'react-redux';

const App = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  return (
    <AzureAppInsights>
      <Provider store={store}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: true, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: 'body', // optional, default to "head", can be "head" or "body",
            nonce: undefined,
          }}
        >
          <Component {...props.pageProps} />
        </GoogleReCaptchaProvider>
      </Provider>
    </AzureAppInsights>
  );
};

// https://github.com/i18next/next-i18next#unserializable-configs
export default appWithTranslation(App, nextI18nConfig);
